import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-reag',
  templateUrl: './contract-reag.component.html',
  styleUrls: ['./contract-reag.component.scss']
})
export class ContractReagComponent implements OnInit {

  public form: FormGroup;
  public lightValue: number = 3558420;
  public standartValue: number = 9520000;
  public approximateAmount: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any


  constructor( private dataShareService: DataShareService, private router: Router,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        siteName: new FormControl(null, Validators.required),
        type: new FormControl(null)
        // type: new FormControl("LIGHT (3.558.420 so'm / 1 ta veb sayt)", Validators.required)
      }
    )
    if(localStorage.getItem('contract-reag')) {
      const formData = JSON.parse(localStorage.getItem('contract-reag'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })

    this.activatedRoute.queryParams.subscribe(params => {
      const url = params.url;
      if(url) {
        localStorage.setItem('url', url)
      } else {
        localStorage.setItem('url', '/')
      }
    })
  }

  onSubmit() {
    // if(this.form.value.type == "5535320") {
    //   this.approximateAmount = Number(this.form.value.type)
    // } else {
    //   this.form.value.type ="3558420"
    //   this.approximateAmount = Number(this.form.value.type)
    // }

    this.form.value.type = 9520000
    this.approximateAmount = this.form.value.type

    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
  }

  addSystem() {
    // if(this.form.value.type == "5535320") {
    //   this.form.value.type = "STANDART (5.535.320 so'm / 1 ta veb sayt)"
    // } else {
    //   this.form.value.type ="LIGHT (3.558.420 so'm / 1 ta veb sayt)"
    // }
    localStorage.setItem('title', "Intеrnеt tarmog`ining UZ milliy sеgmеntida joylashgan vеb-saytlarni axborot xavfsizligi talablari asosida tekshirish")
    localStorage.setItem('contract-reag', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'reaction')
    localStorage.setItem('columnsMain', JSON.stringify( [
      'Sayt nomi',
      'Tarif turi'
    ]))
    localStorage.setItem('columnsTemp', JSON.stringify([
      'siteName',
      'type'
  ]))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }

    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
  }

  back() {
    this.router.navigate(['/'])
  }

}
