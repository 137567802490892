import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-disp',
  templateUrl: './contract-disp.component.html',
  styleUrls: ['./contract-disp.component.scss']
})
export class ContractDispComponent implements OnInit {

  public form: FormGroup;
  // public capitalValue: number = 8975694;
  // public regionValue: number = 10183294;
  public capitalValue: number = 14844417;
  public regionValue: number = 16700000;
  public approximateAmount: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any

  constructor( private dataShareService: DataShareService, private router: Router,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        region: new FormControl(null, Validators.required),
        lang: new FormControl(null, Validators.required)
      }
    )
    if(localStorage.getItem('contract')) {
      const formData = JSON.parse(localStorage.getItem('contract'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })

    this.activatedRoute.queryParams.subscribe(params => {
      const url = params.url;
      if(url) {
        localStorage.setItem('url', url)
      } else {
        localStorage.setItem('url', '/')
      }
    })
  }

  onSubmit() {
    if(this.form.value.region == 1) {
      if(this.form.value.lang === 'Uzbek' || this.form.value.lang === 'Russ') {
        this.approximateAmount = this.capitalValue
      } else {
        this.approximateAmount = this.capitalValue*2
      }
    } else if(Number(this.form.value.region) >1 && Number(this.form.value.region)<15) {
      if(this.form.value.lang === 'Uzbek' || this.form.value.lang === 'Russ') {
        this.approximateAmount = this.regionValue
      } else {
        this.approximateAmount = this.regionValue*2
      }
    }

    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
    //
    localStorage.setItem('title', "Axborot xavfsizlik siyosatini ishlab chiqish")
    localStorage.setItem('contract', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'interaction')
    localStorage.setItem('columnsMain', JSON.stringify(['Hudud', 'Til turi']))
    localStorage.setItem('columnsTemp', JSON.stringify(['region', 'lang']))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      // this.form.reset()
      // this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      // this.form.reset()
      // this.approximateAmount = 0
    }
    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
    this.form.reset()
    this.approximateAmount = 0
  }

  back() {
    this.router.navigate(['/'])
  }

}


