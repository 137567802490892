
    <div class="container">
      <header class="d-flex justify-content-center">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
            <h4 class="pt-4 mb-4 text-center">Konsalting xizmatlarini ko'rsatish (yillik paketlar)</h4>
              <div class="row pt-4">
                  <div class="col-12">
                    <label for="">Xizmat turi (tanishib chiqish uchun):<span class="text-danger">*</span></label>
                    <select name="select" class="form-control w-100" formControlName="serviceType">
                      <option disabled value="" disabled></option>
                      <option disabled value="1">Tashkilotning me'yoriy-huquqiy hujjatlarga muvofiq axborot va kiberxavfsizlik talablariga muvofiqligi bo'yicha baholash o'tkazish</option>
                      <option disabled value="2">Tashkilotning himoya ob'ektlarini xavfsizlik darajasi bo'yicha tasniflash, axborot va kiberxavfsizlik holatini baholash, invertarizatsiyzdan o'tkazish</option>
                      <option disabled value="3">Tashkilotdagi konfidensial ma'lumotlar ro'yxatini va uni himoya qilishni ta'minlash choralarini aniqlash</option>
                      <option disabled value="4">Tashkilotda Axborot xavfsizligi siyosatini ishlab chiqish, qayta ko'rib chiqish yoki yangilanishini baholashda yordam berish</option>
                      <option disabled value="5">Tashkilotda axborot va kiberxavfsizlikni ta'minlash uchun ichki me'yoriy-huquqiy hujjatlarni tayyorlashda yordam berish</option>
                      <option disabled value="6">Tashkilotni axborot va kiberxavfsizlik sohasida huquqiy qo'llab-quvvatlash</option>
                      <option disabled value="7">Tashkilotning axborot va kiberxavfsizligini ta'minlash bo'yicha chora-tadbirlar rejasini tayyorlash</option>
                      <option disabled value="8">Tashkiliy chora-tadbirlarni amalga oshirish va axborot xavfsizligi vositalarini joriy etishga ko'maklashish</option>
                      <option disabled value="9">Tashkilotdagi axborot va kiberxavfsizlik hodisalarini hisobga olishni ta'minlash, shuningdek ularning oqibatlarini bartaraf etishda yordam berish</option>
                      <option disabled value="10">Tashkilot xodimlarini axborot va kiberxavfsizlik bilan ta'minlash bo'yicha seminar-trening o'tkazish</option>
                      <option disabled value="11">Axborot va kiberxavfsizlikni ta'minlash uchun mas'ul bo'lgan mutaxassislar uchun treninglar o'tkazish</option>
                      <option disabled value="12">Axborot va kiberxavfsizlik monitoringi tizimida reyting ko'rsatkichlarini to'ldirish va oshirish bo'yicha maslahatlar</option>
                    </select>
                    <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Hisoblash</button>
                  </div>
                  <div class="col-6">
                    <span>{{approximateAmount.toLocaleString()}} (so'm)</span>
                      <hr>
                  </div>
              </div>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                <button type="button" class="btn btn-lg btn-success mt-3" [disabled]="!contractList.length" (click)="next()">Keyingi</button>
              </div>
          </div>
      </form>
  </header>
</div>



