import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContractService {

  // constructor(private httpClient: HttpClient){}
  constructor(private httpClient: HttpService){}

  //Dev mode
  // public send(params) {
  //   return this.httpClient.postPromise('/contract', {
  //     params
  //   })
  // }

  //Production mode
  public send(params) {
    return this.httpClient.postPromise('/contract', {
      params
    })
  }
}
