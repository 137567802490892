
    <div class="container">
      <header class="d-flex justify-content-center">
        <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
              <h4 class="pt-4 mb-4 text-center">{{title}}</h4>
              <div class="row pt-6">
                <div class="col-12">
                    <label for="">To'lov turi</label>
                    <select name="select" class="form-control w-100 border-0" formControlName="paymentType">
                      <option value="To'g'ridan to'g'ri" selected>To'g'ridan to'g'ri</option>
                      <option value="G'aznachilik">G'aznachilik</option>
                    </select>
                    <hr>
                </div>
                  <div class="col">
                      <label for="">Tashkilot<span class="text-danger">*</span></label>
                      <input type="text" formControlName="organization" class="form-control border-0">
                      <div *ngIf="form.get('organization').invalid && form.get('organization').touched" class="validation">
                        <small><i>Tashkilot</i>  kiritilishi shart</small>
                      </div>
                      <hr>
                  </div>
                  <div class="col-6">
                      <label for="">Yuridik manzil<span class="text-danger">*</span></label>
                      <input type="text" formControlName="jurAddress" class="form-control border-0">
                      <div *ngIf="form.get('jurAddress').invalid && form.get('jurAddress').touched" class="validation">
                        <small><i>Yuridik manzil</i> kiritilishi shart</small>
                      </div>
                      <hr>
                  </div>
                  <div class="col-6">
                      <label for="">Haqiqiy manzil</label>
                      <input type="text" formControlName="factAddress" class="form-control border-0">
                      <hr>
                  </div>
                  <div class="col-6">
                      <label for="">Hisob raqam<span class="text-danger">*</span></label>
                      <input type="text" formControlName="invoice" #invoice (keydown)="onKeysf($event)" class="form-control border-0">
                      <div *ngIf="form.get('invoice').invalid && form.get('invoice').touched" class="validation">
                        <p *ngIf="form.get('invoice').errors.required">Hisob raqam kiritilishi shart</p>
                        <p *ngIf="form.get('invoice').errors.minlength">Raqamlar soni 20 ta bo'lishi kerak</p>
                        <p *ngIf="form.get('invoice').errors.pattern">MFO faqat sonlardan iborat bo'lishi kerak</p>
                      </div>
                      <hr>
                  </div>
                  <div class="col-6">
                      <label for="">Bank nomi<span class="text-danger">*</span></label>
                      <input type="text" formControlName="at" class="form-control border-0">
                      <div *ngIf="form.get('at').invalid && form.get('at').touched" class="validation">
                        <small>Bank nomi kiritilishi shart</small>
                      </div>
                      <hr>
                  </div>
                  <div class="col-6">
                      <label for="">MFO<span class="text-danger">*</span></label>
                      <input type="text" formControlName="mfo" #mfo (keydown)="onKeymfo($event)" class="form-control border-0">
                      <div *ngIf="form.get('mfo').invalid && form.get('mfo').touched" class="validation">
                        <p *ngIf="form.get('mfo').errors.required">MFO kiritilishi shart</p>
                        <p *ngIf="form.get('mfo').errors.minlength">Raqamlar soni 5 ta bo'lishi kerak</p>
                        <p *ngIf="form.get('mfo').errors.pattern">MFO faqat sonlardan iborat bo'lishi kerak</p>
                      </div>
                      <hr>
                  </div>
                  <div class="col-6">
                      <label for="">STIR<span class="text-danger">*</span></label>
                      <input type="text" formControlName="stir" #stir (keydown)="onKeystir($event)" class="form-control border-0">
                      <div *ngIf="form.get('stir').invalid && form.get('stir').touched" class="validation">
                        <p *ngIf="form.get('stir').errors.required">STIR kiritilishi shart</p>
                        <p *ngIf="form.get('stir').errors.minlength">Raqamlar soni 9 ta bo'lishi kerak</p>
                        <p *ngIf="form.get('stir').errors.pattern">STIR faqat sonlardan iborat bo'lishi kerak</p>
                      </div>
                      <hr>
                  </div>
                  <div class="col-6">
                      <label for="">OKPO</label>
                      <input type="text" formControlName="okpo" #okpo (keydown)="onKeyokpo($event)" class="form-control border-0">
                      <div *ngIf="form.get('okpo').invalid && form.get('okpo').touched" class="validation">
                        <p *ngIf="form.get('okpo').errors.maxlength">Raqamlar soni 8 ta dan oshmasligi kerak</p>
                        <p *ngIf="form.get('okpo').errors.minlength">Raqamlar soni kamida 6 ta bo'lishi kerak</p>
                      </div>
                      <hr>
                  </div>
              </div>
              <div class="row mt-2 mb-3">
                  <div class="d-flex justify-content-between">
                    <button type="submit" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                    <div>
                      <button type="button" class="btn btn-lg btn-secondary mt-3" [disabled]="form.invalid" (click)="show()">Arizani ko'rish</button>&nbsp;&nbsp;
                      <button type="button" class="btn btn-lg btn-secondary mt-3" [disabled]="form.invalid" (click)="download()">Arizani yuklab olish</button>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-lg btn-success w-50" [disabled]="form.invalid" (click)="send()">Ariza yuborish</button>
                </div>
              </div>
              <div class="row">
                <span class="text-danger">{{errorMessage}}</span>
              </div>
          </div>
        </form>
      </header>
  </div>
